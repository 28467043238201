import "../../../App.css";
import { useState, useEffect, useRef } from "react";
import { Cookies } from "react-cookie";
import { get, put } from "../../../utils/http";
import { Link } from "react-router-dom";
import { TablaInvitaciones } from "../../../components/Tablas";
import { obtEmpresaUrl } from "../../../utils/global_functions";
import { ModalInicioSocios } from "../../../components/Modals";
import { ComposedChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer, Line, Cell, PieChart, Pie} from 'recharts';


const RADIAN = Math.PI / 180;
const EtiquetaPersonalizada = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if(percent === 0)
    return (<></>);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const CustomizedDot = (props) => {
  const { cx, cy, r, stroke, payload, value } = props;
   const color = payload?.color || stroke;

   return (
      <circle cx={cx} cy={cy} r={r} stroke={color} strokeWidth={1} fill={color} />
   );
};

function GraficaInvitacionesDelAnio({data, barraPrincipal = 1, dataDefault = true}){

  return(
    <>
      <div className="height200px">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart 
            width={200} 
            height={100} 
            data={data}
            barSize={30}
            margin={{
              top: 0,
              right: 0,
              left: -30,
              bottom: 0,
            }}
          >
            <XAxis dataKey="mes" />
            <YAxis allowDecimals={false}/>
            <Bar dataKey="invitaciones">
            {
              data.map((entry, index) => (
                <Cell key={`cell-${index}`} className={data[index].id === barraPrincipal ? "grafica_color_empresa" : ""} fill="#D7D7D7"/>
              ))
            }
            </Bar>
            <Line dataKey="invitaciones" type="monotone" stroke="#D7D7D7" dot={<CustomizedDot r={2}/>}/>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      {
      dataDefault && (
        <p className="margin0">
          ¡Bienvenido! Aquí verás tus métricas personalizadas en cuanto empieces a interactuar con nuestro servicio.
        </p>
      )
    }
    </>
  );
}

function GraficaEstatusInvitaciones({idSocio = 0}){
  const COLOR_NO_DATOS = ['#cacfd2', '#bdc3c7', '#a6acaf', '#909497'];
  const COLOR_DATOS = ['#51B9CF', '#4BBB7A', '#4B75BB', '#9C36B2'];
  const [data, setData] = useState([
    { name: 'Enviado', invitaciones: 1 },
    { name: 'Abierta', invitaciones: 1 },
    { name: 'Cta. creada', invitaciones: 1 },
    { name: 'Banco seleccionado', invitaciones: 1 },
  ]);
  const [esqueleto, setEsqueleto] = useState(true);
  const [sinInvitaciones, setSinInvitaciones] = useState(false);

  useEffect(() => {
    async function obtenerDatos(){
      const peticion = await get("obtEstatusInvitaciones", idSocio);

      if(peticion?.statuscode === 200){
        setData(peticion.respuesta);
        setEsqueleto(false);
      }else if(peticion?.statuscode === 404){
        setData([
          { name: 'Enviado', invitaciones: 1 },
          { name: 'Abierta', invitaciones: 2 },
          { name: 'Cta. creada', invitaciones: 3 },
          { name: 'Banco seleccionado', invitaciones: 4 },
        ]);
        setEsqueleto(true);
        setSinInvitaciones(true);
      }
    }

    obtenerDatos();
  }, []);


  return(
    <>
      <div className="height200px">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart
            width={200} 
            height={100} 
            data={data} 
            barSize={30}
            margin={{right: -10}}
          >
            <Legend layout="vertical" align="right" verticalAlign="top" width="min-content"/>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={esqueleto ? undefined : EtiquetaPersonalizada}
              outerRadius={80}
              fill="#8884d8"
              dataKey="invitaciones"
            >
            {
              esqueleto ? (
                data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLOR_NO_DATOS[index % COLOR_NO_DATOS.length]} />
                ))
              ) : (
                data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLOR_DATOS[index % COLOR_DATOS.length]} />
                ))
              )
            }
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    {
      esqueleto && sinInvitaciones && (
        <p className="margin0">
          ¡Bienvenido! Aquí verás tus métricas personalizadas en cuanto empieces a interactuar con nuestro servicio.
        </p>
      )
    }
    </>
  );
}

export default function InvitacionesSocios() {
  const [isLoading, setIsLoading] = useState(true);
  const cookies = new Cookies();
  const idSocios = cookies.get("idSocio");
  const [dataInvitaciones, setDataInvitaciones] = useState("");
  const [empresaLogo, setEmpresaLogo] = useState("");
  const [habilitarCarrusel, setHabilitarCarrusel] = useState(false);
  const [dataDefault, setDataDefault] = useState(true);
  const [estatusInvitaciones, setEstatusInvitaciones] = useState(
    {
      "invitacionesDelAnio": {
        mejorMes: 1,
        estadisticas: [
          {
            "id": 1,
            "mes": "enero",
            "invitaciones": 0
          },
          {
            "id": 2,
            "mes": "febrero",
            "invitaciones": 0
          },
          {
            "id": 3,
            "mes": "marzo",
            "invitaciones": 0
          },
          {
            "id": 4,
            "mes": "abril",
            "invitaciones": 0
          },
          {
            "id": 5,
            "mes": "mayo",
            "invitaciones": 0
          },
          {
            "id": 6,
            "mes": "junio",
            "invitaciones": 0
          },
          {
            "id": 7,
            "mes": "julio",
            "invitaciones": 0
          },
          {
            "id": 8,
            "mes": "agosto",
            "invitaciones": 0
          },
          {
            "id": 9,
            "mes": "septiembre",
            "invitaciones": 0
          },
          {
            "id": 10,
            "mes": "octubre",
            "invitaciones": 0
          },
          {
            "id": 11,
            "mes": "noviembre",
            "invitaciones": 0
          },
          {
            "id": 12,
            "mes": "diciembre",
            "invitaciones": 0
          }
        ]
      },
      "totalInvitaciones": {
        "ultimoMes": 0,
        "ultimoAnio": 0
      }
    }
  );

  const cierraCarrusel = () => {
    setHabilitarCarrusel(false);
    localStorage.setItem("carruselInicioSesion", false);
  }

  //Obtenemos información general
  const obtInformacion = async () => {
    const [peticionInvitaciones, peticionEstatusInvitaciones] = await Promise.all([get("getInvitaciones", idSocios), get("obtInvitacionesUltimosMeses", idSocios)]);
    
    if (peticionInvitaciones.statuscode === 200) {
      setDataInvitaciones(peticionInvitaciones.invitaciones);
    }

    if(peticionEstatusInvitaciones.statuscode === 200){
      setEstatusInvitaciones(peticionEstatusInvitaciones.respuesta);
      setDataDefault(false);
    }else if (peticionEstatusInvitaciones.statuscode === 404){
      setEstatusInvitaciones(
        {
          "invitacionesDelAnio": {
            mejorMes: 0,
            estadisticas: [
              {
                "id": 1,
                "mes": "enero",
                "invitaciones": 10
              },
              {
                "id": 2,
                "mes": "febrero",
                "invitaciones": 20
              },
              {
                "id": 3,
                "mes": "marzo",
                "invitaciones": 30
              },
              {
                "id": 4,
                "mes": "abril",
                "invitaciones": 40
              },
              {
                "id": 5,
                "mes": "mayo",
                "invitaciones": 50
              },
              {
                "id": 6,
                "mes": "junio",
                "invitaciones": 60
              },
              {
                "id": 7,
                "mes": "julio",
                "invitaciones": 80
              },
              {
                "id": 8,
                "mes": "agosto",
                "invitaciones": 70
              },
              {
                "id": 9,
                "mes": "septiembre",
                "invitaciones": 60
              },
              {
                "id": 10,
                "mes": "octubre",
                "invitaciones": 50
              },
              {
                "id": 11,
                "mes": "noviembre",
                "invitaciones": 40
              },
              {
                "id": 12,
                "mes": "diciembre",
                "invitaciones": 30
              }
            ]
          },
          "totalInvitaciones": {
            "ultimoMes": 0,
            "ultimoAnio": 0
          }
        }
      );
      setDataDefault(true);
    }else{
      setDataDefault(true);
    }

  };

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        const empresa = await obtEmpresaUrl();
        await obtInformacion();


        setEmpresaLogo(empresa.configuracion.img);

        if (empresa.configuracion.nombre.toLowerCase() === "remax"){
          if (localStorage.getItem("carruselInicioSesion") === null){
            const respuesta = await get("primerInicioSesion", cookies.get("idSocio"));
            if (respuesta.statuscode === 200){
              setHabilitarCarrusel(!respuesta.primerInicioSesion);
              localStorage.setItem("carruselInicioSesion", !respuesta.primerInicioSesion);
              if (!respuesta.primerInicioSesion){
                put("primerInicioSesion", {idSocio: cookies.get("idSocio"), primerInicioSesion: true});
              }
            }
          }else{
            setHabilitarCarrusel(localStorage.getItem("carruselInicioSesion") === "true" ? true : false);
          }
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}

      {
        habilitarCarrusel && (
          <ModalInicioSocios logo={empresaLogo} onClose={cierraCarrusel}/>
        )
      }

      <div className=" cont_Info">
        <h1 className="titt">Invitaciones​</h1>
        <p>Aquí puedes invitar  a tus clientes y visualizar el estatus de las invitaciones generadas.</p>

        <div id="graficas_invitaciones" className="cont_grid">
          <div className="card_contenedor">
            <h3>Total de invitaciones</h3>

            <div className="grafico_invitaciones">
              <div>
                <p className="margin0">Por mes</p>
                <p className="margin0"><strong>{estatusInvitaciones?.totalInvitaciones?.ultimoMes} invitaciones</strong></p>
              </div>
              <br/>
              <br/>
              <div>
                <p className="margin0">Por año</p>
                <p className="margin0"><strong>{estatusInvitaciones?.totalInvitaciones?.ultimoAnio} invitaciones</strong></p>
              </div>
            </div>
          </div>

          <div className="card_contenedor">
            <h3>Invitaciones del año</h3>

              <GraficaInvitacionesDelAnio 
                data={estatusInvitaciones.invitacionesDelAnio.estadisticas} 
                barraPrincipal={estatusInvitaciones.invitacionesDelAnio.mejorMes}
                dataDefault={dataDefault}
              />
          </div>
          
          <div className="card_contenedor">
            <h3>Estatus invitaciones</h3>
            <div>
              <GraficaEstatusInvitaciones idSocio={idSocios}/>
            </div>
          </div>
        </div>
        

        <p>
          Nota: Una vez tu cliente este como precalificado lo podrás ver en la pestaña de <Link to="/panelSocios" className="sin_ruptura"> mis clientes</Link>
        </p>
        <div className="cont_flex cont-form">
          <div className="dosCuttonFormularios">
            <Link
              className="btn btn_bco col3 campo-form "
              to="/frmNuevoCliente"
            >
              Enviar nueva invitación a cliente
            </Link>
          </div>
        </div>
        <TablaInvitaciones arrData={dataInvitaciones}></TablaInvitaciones>
      </div>
    </>
  );
}
