import { Navigate, Outlet, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  desencriptarToken,
  obtInformacionUsuarioSocioRemax,
} from "../utils/global_functions";
import Cookies from "universal-cookie";
import { get } from "../utils/http";

export const Auth = ({ session, children, path = "/" }) => {
  const location = useLocation();

  if (!session){
    const redireccion = location.pathname !== '/' ? `/?redirect=${location.pathname}` : '/';
    return <Navigate to={redireccion}></Navigate>;
  }

  return <Outlet />;
};

export const NoAuth = ({ session, children, path = "/" }) => {
  if (session) return <Navigate to="/home"></Navigate>;
  return <Outlet />;
};

export const NoAuthSocios = ({ session, children, path = "/" }) => {
  if (session) return <Navigate to="/panelSocios"></Navigate>;
  return <Outlet />;
};

export const AuthSocio = ({ session, path = "/inicioSocios" }) => {
  const [verificacionCompleta, setVerificacionCompleta] = useState(false);
  const cookie = new Cookies();

  function activarSesion(infoUser){
    //console.log(response);
    cookie.set("empresaSocio", true, { path: "/" });
    cookie.set("sessionSocio", true, { path: "/" });
    cookie.set(
      "nombreSocio",
      infoUser.nombre + " " + infoUser.paterno,
      { path: "/" }
    );
    cookie.set("correoSocio", infoUser.correo, { path: "/" });
    cookie.set("idSocio", infoUser.idSocios, { path: "/" });
  }

  const realizarVerificacion = async () => {
    try {
      // Obtener la URL actual
      const urlActual = window.location.href;

      // Obtener los par�metros de la URL
      const url = new URL(urlActual);

      // Imprime la cadena de b�squeda completa
      //console.log("Cadena de b�squeda:", url.search);

      // Obtener el valor del par�metro "token"
      const token = url.searchParams.get("token");
      //console.log(token);

      if (token) {
        cookie.remove("sessionSocio", { path: "/" });
        cookie.remove("nombreSocio", { path: "/" });
        cookie.remove("correoSocio", { path: "/" });
        cookie.remove("idSocio", { path: "/" });
        cookie.remove("empresaSocio", { path: "/" });

        let tokenDesencriptado = await desencriptarToken(token);
        //console.log(tokenDesencriptado);

        if (tokenDesencriptado !== false) {
          // Utilizar una expresi�n regular para extraer solo los d�gitos hasta que aparezca la primera letra
          const soloDigitos = tokenDesencriptado.match(/^\d+/);

          // Si soloDigitos no es nulo, obtienes el primer elemento (que ser�a la coincidencia de d�gitos)
          const numeros = soloDigitos ? soloDigitos[0] : null;
          //console.log(numeros);

          //let infoUser = await obtInformacionUsuarioRemax(numeros);
          let infoUser = await obtInformacionUsuarioSocioRemax(numeros);

          if (infoUser !== false) {
            activarSesion(infoUser);
          }else{ //Verificar token para otras empresas
            //console.log("Segunda verificaci�n: ", tokenDesencriptado);
            const jsonString = tokenDesencriptado.match(/\{"[a-zA-Z]+":[0-9]+,"[a-zA-Z]+":[0-9]+\}/);

            if (jsonString[0]){
              const json = JSON.parse(jsonString[0]);

              if(json?.idUsuario && json?.idEmpresa){
                const respuesta = await get("empresa/obtInformacionUsuarioSocio", json.idUsuario);

                if (respuesta?.statuscode === 200 && respuesta?.data){
                  activarSesion(respuesta.data);
                }
              }
            }
          }
        }
      } else {
        console.error("No se encontr� el par�metro 'token' en la URL");
      }
    } catch (error) {
      console.error("Error durante la verificaci�n:", error);
    } finally {
      // Establecer verificaci�n como completa, ya sea �xitosa o no
      setVerificacionCompleta(true);
    }
  };

  useEffect(() => {
    const cargarDatos = async () => {
      await realizarVerificacion();
      // El resto del c�digo que deseas ejecutar despu�s de la verificaci�n

      if (!session && !cookie.get("sessionSocio")) {
        //console.log("entrando");
        // Solo redirigir si la verificaci�n est� completa
        setVerificacionCompleta(true);
      }
    };

    cargarDatos();
  }, []);

  if (!verificacionCompleta) {
    // Muestra un componente de carga o mensaje mientras la verificaci�n est� en curso
    return <div>Cargando...</div>;
  }

  if (!session && !cookie.get("sessionSocio")) {
    //console.log("entrando");
    return <Navigate to={path} />;
  }

  return <Outlet />;
};

export const AuthVendedor = () => {
  const cookie = new Cookies();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  function limpiarCookies(){
    cookie.remove("sessionVendedor", {path: "/documentosViviendaVendedor"});
    cookie.remove("idUsuario", {path: "/documentosViviendaVendedor"});
    cookie.remove("idResPre", {path: "/documentosViviendaVendedor"});
    cookie.remove("idCuestionario", {path: "/documentosViviendaVendedor"});
    cookie.remove("token", {path: "/documentosViviendaVendedor"});
    cookie.remove("tokenExp", {path: "/documentosViviendaVendedor"});
  }

  const realizarVerificacion = async () => {
    try {
      
      const token = queryParams.get("token");

      if (token) {
        limpiarCookies();

        let tokenDesencriptado = await desencriptarToken(token);

        if (tokenDesencriptado !== false) {
          const datos = JSON.parse(tokenDesencriptado);

          if(datos?.IdResPre && datos?.IdCuestionario && datos?.IdUsuario && datos?.Exp){
            //console.log("Sesi�n iniciada");
            //console.log("Exp:", datos.Exp.toString());
            
            cookie.remove("tokenExp", {path: "/documentosViviendaVendedor"});
            cookie.set("sessionVendedor", true, {path: "/documentosViviendaVendedor", expires: new Date(datos.Exp) });
            cookie.set("idUsuario", parseInt(datos.IdUsuario), {path: "/documentosViviendaVendedor" });
            cookie.set("idResPre", parseInt(datos.IdResPre), {path: "/documentosViviendaVendedor" });
            cookie.set("idCuestionario", parseInt(datos.IdCuestionario), {path: "/documentosViviendaVendedor" });
            cookie.set("token", token, {path: "/documentosViviendaVendedor" });
          }
        }
      } else {
        console.error("No se encontr� el par�metro 'token' en la URL");
      }

      if(cookie.get("sessionVendedor") === undefined){
        cookie.set("tokenExp", true, {path: "/documentosViviendaVendedor"});
      }

    } catch (error) {
      console.error("Error durante la verificaci�n:", error);
    }
  };

  useEffect(() => {
    async function inicializarProc(){
      await realizarVerificacion();
    }

    inicializarProc();
  }, []);

  /*if (!session && !cookie.get("sessionVendedor") && !cookie.get("tokenExp"))
    return <Navigate to="/"></Navigate>;*/

  return <Outlet />;
}