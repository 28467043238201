import * as ReactDOM from "react-dom/client";
import { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "../../App.css";

import { Link } from "react-router-dom";    
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { obtEmpresaUrl } from "../../utils/global_functions";
import { formatoMoneda, round, sumarCapitalizacionFecha } from "../../utils/operaciones_formulario";
import { formatoDinero, textoAMoneda, agergarDecimales } from "../../utils/operaciones_formulario";
import { post, put, get, getFile } from "../../utils/http";
import Cookies from "universal-cookie";
import { ModalInstruccinesAmortizacion } from "../../components/Modals";

export default function AmortizacionHipoteca() {
  const [isLoading, setIsLoading] = useState(true);
  const [hipoteca, setHipoteca] = useState({});
  const [registrosAmortizacion, setRegistrosAmortizacion] = useState([]);
  const [aportacionesAmortizacion, setAportacionesAmortizacion] = useState({});
  const [capitalizaciones, setCapitalizaciones] = useState([]);
  const [recordatorio, setRecordatorio] = useState(false);
  const [editarDatos, setEditarDatos] = useState(false);

  // Estados auxiliares para operaciones
  const [periodoFinal, setPeriodoFinal] = useState(undefined);
  const [tasaXPeriodoForm, setTasaXPeriodoForm] = useState(undefined);

  const [pop, setPop] = useState(true);
  const cookies = new Cookies();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: {errors}
  } = useForm({
    defaultValues: {
      montoLote: "$ 0.00",
    }
  });

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        await renderizarDropdowns();
        await obtInfoAmortizacion();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    window.scrollTo(0, 0);
    cargarDatos();

  }, []);
  
  // Definición de las columnas de las tablas
  const columnDefs = [
    { headerName: "Periodo", field: "periodo", width: 100},
    { 
      headerName: "Fecha", 
      field: "fecha", width: 150, 
      valueFormatter: param => `${param.value.getDate().toString().padStart(2, '0')}/${(param.value.getMonth()+1).toString().padStart(2, '0')}/${param.value.getFullYear()}`
    },
    { headerName: "Saldo Inicial", field: "saldoInicial", valueFormatter: param => formatoDinero(param.value), },
    { headerName: "Intereses", field: "intereses", valueFormatter: param => formatoDinero(param.value), width: 180},
    { headerName: `Pago ${hipoteca.capitalizacion} Sin Costos Adicionales`, field: "pagoMensualSinCostosAd", valueFormatter: param => formatoDinero(param.value), width: 180},
    { headerName: "Seguro de Vida", field: "seguroVida", valueFormatter: param => formatoDinero(param.value), width: 150},
    { headerName: "Seguro de Daños", field: "seguroDanios", valueFormatter: param => formatoDinero(param.value), width: 150},
    { headerName: `Pago Total ${hipoteca.capitalizacion}`, field: "pagoTotalMensual", valueFormatter: param => formatoDinero(param.value), width: 180},
    { 
      headerName: "Aportación Adicional a Capital", 
      field: "aportacionAdCapital", 
      editable: true, 
      valueSetter: param => {
        if (param.newValue === null){
          param.data.aportacionAdCapital = 0;
        }else{
          param.data.aportacionAdCapital = param.newValue;
        }
        return true;
      },
      valueFormatter: param => param.value === 0 ? "" : formatoDinero(param.value),
      width: 180,
      cellClass: "tabla_amort_aportacion_cell"
    },
    { 
      headerName: "Pago a Capital", 
      field: "pagoCapital",
      valueFormatter: param => formatoDinero(param.value),
      width: 150
    },
    { 
      headerName: "Saldo Final", 
      field: "saldoFinal",
      valueFormatter: param => formatoDinero(param.value),
    }
  ];

  async function renderizarDropdowns(){
    let root;
    let opcionDefault = (
      <option value="" selected disabled>
        Seleccione una opción...{" "}
      </option>
    );

    let catalogos = await get("getCatalogosHipoteca", "");
    if (catalogos.statuscode === 200) {
      //console.log("catalogos: ", catalogos);

      let bancos = catalogos.respuesta.bancosHipoteca.map((banco) => (
        <option value={banco.idBanco} key={banco.idBanco}>
          {banco.nombreBanco}
        </option>
      ));
      bancos.unshift(opcionDefault);
      root = ReactDOM.createRoot(document.getElementById("selectBancosHipoteca2"));
      root.render(bancos);

      setCapitalizaciones(catalogos.respuesta.catCapitalizaciones);
      let capitalizaciones = catalogos.respuesta.catCapitalizaciones.map((capitalizacion) => (
        <option value={capitalizacion.idCapitalizacion} key={capitalizacion.idCapitalizacion}>
          {capitalizacion.nombreCapitalizacion}
        </option>
      ));

      capitalizaciones.unshift(opcionDefault);
      root = ReactDOM.createRoot(document.getElementById("selectCapitalizacionHipoteca2"));
      root.render(capitalizaciones);
    }
    
  }

  async function obtInfoAmortizacion(){
    const idHipoteca = parseInt(localStorage.getItem("idHipoteca"));

    const respuesta = await post("getInfoAmortizacionHipoteca", {
      idUser: parseInt(cookies.get("id")),
      idHipoteca
    });

    let infoHipoteca = {};
    let aportacionesHipoteca = [];

    if (respuesta.statuscode === 200){
      const hipoteca = respuesta.respuesta.infoHipoteca;
      const aportaciones = JSON.parse(respuesta.respuesta.aportaciones);
      
      //console.log("InfoHipoteca: ", hipoteca);
      //console.log("Aportaciones:", aportaciones);

      infoHipoteca = hipoteca;
      aportacionesHipoteca = aportaciones ? aportaciones : {};

      infoHipoteca.tasaPorPeriodo = (hipoteca.tasaAnual / 100) / hipoteca.capitalizacionValor;
      setTasaXPeriodoForm(infoHipoteca.tasaPorPeriodo);

      //setValue("identificador", infoHipoteca.identificador);
      setValue("montoOriginal", formatoMoneda(hipoteca.montoOriginalCredito));
      setValue("tasaAnual", hipoteca.tasaAnual + "%");
      setValue("plazoCredito", hipoteca.plazoCredito + (hipoteca.plazoCredito > 1 ? " años" : "año"));
      setValue("capitalizacion", hipoteca.idCatCapitalizacion);
      setValue("banco", hipoteca.idCatBanco);
      setValue("saldoCredito", formatoMoneda(hipoteca.saldoCredito));
      setValue("pagoMensual", formatoMoneda(hipoteca.pagoMensual));
      setValue("periodosTotales", hipoteca.periodosTotales);
      setValue("periodosFaltantes", hipoteca.periodoFinal - hipoteca.periodoInicial + 1);
      setValue("estimacionValorPropiedad", hipoteca.estimacionValorPropiedad ? formatoMoneda(hipoteca.estimacionValorPropiedad) : "ND");
      setPeriodoFinal(hipoteca.periodoFinal);

      setRecordatorio(hipoteca.idCatRecordatorio === 2 ? true : false);

    }else{

    }

    const registros = crearRegistrosAmortizacion(infoHipoteca, aportacionesHipoteca)
      .filter(registro => registro.periodo >= infoHipoteca.periodoInicial && registro.periodo <= infoHipoteca.periodoFinal);

    const ultimoRegistro = registros.filter(registro => registro.saldoInicial > 0).at(-1);
    setPeriodoFinal(ultimoRegistro.periodo);

    setHipoteca(infoHipoteca);
    setRegistrosAmortizacion(registros);
    setAportacionesAmortizacion(aportacionesHipoteca);
  }

  const guardarAportaciones = async () => {
    //console.log(aportacionesAmortizacion);

    const respuesta = await put("updateAmortizacionHipoteca", {
      idUser: parseInt(cookies.get("id")),
      idHipoteca: parseInt(localStorage.getItem("idHipoteca")),
      aportacionesjson: JSON.stringify(aportacionesAmortizacion),
      idCatRecordatorio: recordatorio ? 2 : 1,
      periodoFinal: periodoFinal
    });

    if(respuesta.statuscode === 200){
      Swal.fire({
        icon: "success",
        title: "Aportaciones guardadas",
        confirmButtonText: "Aceptar"
      });
    }else{
      Swal.fire({
        icon: "error",
        title: "Algo salió mal",
        text: "Inténtelo nuevamente más tarde.",
        confirmButtonText: "Aceptar"
      });
    }

  }

  function crearRegistrosAmortizacion(infoHipoteca, aportacionesHipoteca){
    let fechaAux = new Date(infoHipoteca.fechaApertura);

    let periodo = infoHipoteca.periodoInicial;
    sumarCapitalizacionFecha(fechaAux, infoHipoteca.periodosTotales - infoHipoteca.periodosFaltantes, infoHipoteca.capitalizacion);
    let fecha = new Date(fechaAux);
    let saldoInicial = round(infoHipoteca.saldoCredito, 2);
    let intereses = round(saldoInicial * infoHipoteca.tasaPorPeriodo, 2);
    let pagoMensualSinCostosAd = round(infoHipoteca.pagoMensual, 2);
    let seguroVida = round(infoHipoteca.costoSeguroVida, 2);
    let seguroDanios = round(infoHipoteca.costoSeguroDanios, 2);
    let pagoTotalMensual = round(pagoMensualSinCostosAd + seguroVida + seguroDanios, 2);
    let aportacionAdCapital = round(aportacionesHipoteca[periodo.toString()] ? aportacionesHipoteca[periodo.toString()] : 0, 2);
    let pagoCapital = round(pagoMensualSinCostosAd -  intereses, 2);
    let saldoFinal = round(saldoInicial - pagoCapital - aportacionAdCapital, 2);

    let registros = [{
      periodo,
      fecha,
      saldoInicial,
      intereses,
      pagoMensualSinCostosAd,
      seguroVida,
      seguroDanios,
      pagoTotalMensual,
      aportacionAdCapital,
      pagoCapital,
      saldoFinal
    }];

    for (let i = 1; i < infoHipoteca.periodosTotales ; i++) {

      periodo++;
      sumarCapitalizacionFecha(fechaAux, 1, infoHipoteca.capitalizacion);
      fecha = new Date(fechaAux);
      saldoInicial = round(registros[i-1].saldoFinal, 2);
      intereses = round(saldoInicial * infoHipoteca.tasaPorPeriodo, 2);
      pagoMensualSinCostosAd = round(infoHipoteca.pagoMensual, 2);
      seguroVida = round(infoHipoteca.costoSeguroVida, 2);
      seguroDanios = round(infoHipoteca.costoSeguroDanios, 2);
      pagoTotalMensual = round(pagoMensualSinCostosAd + seguroVida + seguroDanios, 2);
      aportacionAdCapital = round(aportacionesHipoteca[periodo] ? aportacionesHipoteca[periodo] : 0, 2);
      pagoCapital = round(pagoMensualSinCostosAd - intereses, 2);
      saldoFinal = round(saldoInicial - pagoCapital - aportacionAdCapital, 2);

      // Remate para llegar a cero en saldo final
      if (saldoFinal < 0){
        pagoMensualSinCostosAd = round(saldoInicial + intereses, 2);

        pagoTotalMensual = round(pagoMensualSinCostosAd + seguroVida + seguroDanios, 2);
        pagoCapital = round(pagoMensualSinCostosAd - intereses, 2);
        saldoFinal = round(saldoInicial - pagoCapital - aportacionAdCapital,2);
      }

      registros.push({
        periodo,
        fecha,
        saldoInicial,
        intereses,
        pagoMensualSinCostosAd,
        seguroVida,
        seguroDanios,
        pagoTotalMensual,
        aportacionAdCapital,
        pagoCapital,
        saldoFinal,
      });
    }

    return registros;
  }

  function actualizarRegistrosAmortizacion(periodo, registrosAmort, aportaciones){
    const indiceRegistro = registrosAmort.findIndex(registro => registro.periodo === periodo);
    
    registrosAmort[indiceRegistro].aportacionAdCapital = round(aportaciones[periodo] ? aportaciones[periodo] : 0, 2);
    registrosAmort[indiceRegistro].saldoFinal = round(registrosAmort[indiceRegistro].saldoInicial - registrosAmort[indiceRegistro].pagoCapital - registrosAmort[indiceRegistro].aportacionAdCapital,2);
    
    for (let i = indiceRegistro + 1; i < registrosAmort.length; i++) {
      registrosAmort[i].saldoInicial = round(registrosAmort[i-1].saldoFinal, 2);
      registrosAmort[i].intereses = round(registrosAmort[i].saldoInicial * hipoteca.tasaPorPeriodo, 2);
      registrosAmort[i].pagoMensualSinCostosAd = round(hipoteca.pagoMensual, 2);
      registrosAmort[i].seguroVida = round(hipoteca.costoSeguroVida, 2);
      registrosAmort[i].seguroDanios = round(hipoteca.costoSeguroDanios, 2);
      registrosAmort[i].pagoTotalMensual = round(registrosAmort[i].pagoMensualSinCostosAd + registrosAmort[i].seguroVida + registrosAmort[i].seguroDanios, 2);
      registrosAmort[i].aportacionAdCapital = round(aportaciones[registrosAmort[i].periodo] ? aportaciones[registrosAmort[i].periodo] : 0, 2);
      registrosAmort[i].pagoCapital = round(registrosAmort[i].pagoMensualSinCostosAd - registrosAmort[i].intereses, 2);
      registrosAmort[i].saldoFinal = round(registrosAmort[i].saldoInicial - registrosAmort[i].pagoCapital - registrosAmort[i].aportacionAdCapital, 2);

      // Remate para llegar a cero en saldo final
      if (registrosAmort[i].saldoFinal < 0){
        registrosAmort[i].pagoMensualSinCostosAd = round(registrosAmort[i].saldoInicial + registrosAmort[i].intereses, 2);

        registrosAmort[i].pagoTotalMensual = round(registrosAmort[i].pagoMensualSinCostosAd + registrosAmort[i].seguroVida + registrosAmort[i].seguroDanios, 2);
        registrosAmort[i].pagoCapital = round(registrosAmort[i].pagoMensualSinCostosAd - registrosAmort[i].intereses, 2);
        registrosAmort[i].saldoFinal = round(registrosAmort[i].saldoInicial - registrosAmort[i].pagoCapital, 2);

        aportaciones[registrosAmort[i].periodo.toString()] = 0;
        registrosAmort[i].aportacionAdCapital = 0;
        
      }
    }

    const ultimoRegistro = registrosAmortizacion.filter(registro => registro.saldoInicial > 0).at(-1);
    setPeriodoFinal(ultimoRegistro.periodo);
    setValue("periodosFaltantes", ultimoRegistro.periodo - hipoteca.periodoInicial + 1);
  }

  const aportarEnLote = (datos) => {
    let aportaciones = {};

    for (let i = hipoteca.periodoInicial; i < periodoFinal; i++) {
      aportaciones[i.toString()] = Number(datos.montoLote.replace(/[^0-9.]/g, "")); 
    }

    actualizarRegistrosAmortizacion(hipoteca.periodoInicial, registrosAmortizacion, aportaciones);

    setAportacionesAmortizacion(aportaciones);
  }

  const actualizarDatosHipoteca = async (datos) => {
    //console.log(datos);
    const datosActualizados = {
      idCatBanco: Number(datos.banco),
      tasaAnual: Number(datos.tasaAnual.replace(/[^0-9.]/g, "")),
      montoOriginalCredito: Number(datos.montoOriginal.replace(/[^0-9.]/g, "")),
      plazoCredito: Number(datos.plazoCredito.replace(/[^0-9]/g, "")),
      idCatCapitalizacion: Number(datos.capitalizacion),
      saldoCredito: Number(datos.saldoCredito.replace(/[^0-9.]/g, "")),
      periodosTotales: Number(datos.periodosTotales),
      pagoMensual: Number(datos.pagoMensual.replace(/[^0-9.]/g, ""))
    }

    const capitalizacion = capitalizaciones.find(cap => cap.idCapitalizacion === datosActualizados.idCatCapitalizacion);
    const tasaPorPeriodo = (datosActualizados.tasaAnual / 100) / capitalizacion.valorAnual;
    datosActualizados.capitalizacion = capitalizacion.nombreCapitalizacion;

    datosActualizados.periodoFinal = datosActualizados.periodosTotales;

    const registros = crearRegistrosAmortizacion({...hipoteca, ...datosActualizados, tasaPorPeriodo: tasaPorPeriodo}, (datosActualizados.periodosTotales !== hipoteca.periodosTotales) ? {} : aportacionesAmortizacion)
      .filter(registro => registro.periodo >= hipoteca.periodoInicial && registro.periodo <= datosActualizados.periodoFinal);

    const ultimoRegistro = registros.filter(registro => registro.saldoInicial > 0).at(-1);
    setPeriodoFinal(ultimoRegistro.periodo);
    
    setValue("periodosFaltantes", ultimoRegistro.periodo - hipoteca.periodoInicial + 1);

    setRegistrosAmortizacion(registros);
    setHipoteca({...hipoteca, ...datosActualizados, tasaPorPeriodo: tasaPorPeriodo});
    
    await put("updateHipoteca", {
      idUser: parseInt(cookies.get("id")),
      idHipoteca: localStorage.getItem("idHipoteca") ? parseInt(localStorage.getItem("idHipoteca")) : 0,
      ...hipoteca,
      ...datosActualizados
    });

    setEditarDatos(false);
  }

  const cancelarCambios = () => {
    setValue("montoOriginal", formatoMoneda(hipoteca.montoOriginalCredito));
    setValue("tasaAnual", hipoteca.tasaAnual + "%");
    setValue("plazoCredito", hipoteca.plazoCredito + (hipoteca.plazoCredito > 1 ? " años" : "año"));
    setValue("capitalizacion", hipoteca.idCatCapitalizacion);
    setValue("banco", hipoteca.idCatBanco);
    setValue("saldoCredito", formatoMoneda(hipoteca.saldoCredito));
    setValue("pagoMensual", formatoMoneda(hipoteca.pagoMensual));
    setValue("periodosTotales", hipoteca.periodosTotales);
    setValue("periodosFaltantes", periodoFinal - hipoteca.periodoInicial + 1);
    
    setEditarDatos(false);
  }

  const exportarAExcel = async () => {
    const idHipoteca = localStorage.getItem("idHipoteca");

    if(idHipoteca){
      const resultado = await getFile("obtExcelTablaAmortizacion", parseInt(idHipoteca));
      if (resultado !== 1){
        Swal.fire({
          icon: "error",
          title: "Algo salió mal",
          text: "Inténtelo nuevamente más tarde.",
          confirmButtonText: "Aceptar"
        });
      }
    }
  }

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}

      <div className=" cont_Info">
        {
          pop && (
            <ModalInstruccinesAmortizacion
              onClose={() => setPop(false)}
            />
          )
        }
        <Link to="/miHipoteca" className="link linkGrande back">
          {" "}
          {"<"} Regresar a Mis Hipotecas
        </Link>
        <br></br>
        <div>
          <h1 className="titt">{hipoteca.identificador}</h1>
        </div>
        
        <div className="Resum">
          <div className="flex_derecha">
            <a
              href="#/"
              className="link"
              onClick={() => {
                setEditarDatos(true);
              }}
            >
              Editar <i className="fa fa-pencil"></i>
            </a>
          </div>
          <form
            className={!editarDatos ? "inactivo" : ""} 
            onSubmit={handleSubmit(actualizarDatosHipoteca)}
          >
            <div className="col3 campo-form">
              <span className="negritas">Valor Original del Crédito:</span>
              <input
                type="text"
                autoComplete="off"
                disabled={!editarDatos}
                {...register("montoOriginal", {required: true})}
                onInput={(e) => {
                  e.target.value = textoAMoneda(e.target.value);
                }}
                onBlur={(e) => {
                  e.target.value = agergarDecimales(e.target.value);
                }}               
              />
              {errors.montoOriginal?.type === "validate" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
            <div className="col3 campo-form">
              <span className="negritas">Tasa:</span>
              <input
                type="text"
                autoComplete="off"
                disabled={!editarDatos}
                {...register("tasaAnual", { required: true})}
                onFocus={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9.]/g, "");
                }}
                onInput={(e) => {
                  let porcentaje = e.target.value.replace(/[^0-9.]/g, "");
                  const partes = porcentaje.split(".");

                  if (partes.length > 2) {
                    porcentaje = partes[0] + "." + partes[1];
                  }
                  
                  if (partes[1] && partes[1].length > 2) {
                    porcentaje = partes[0] + "." + partes[1].substring(0, 2);
                  }

                  if(getValues("capitalizacion")){
                    const capitalizacion = capitalizaciones.find(cap => cap.idCapitalizacion === Number(getValues("capitalizacion")));
                    setTasaXPeriodoForm((porcentaje / 100) / capitalizacion.valorAnual);
                  }

                  e.target.value = porcentaje;
                }}
                onBlur={(e) => {
                  if(e.target.value.endsWith(".")){
                    e.target.value = e.target.value.replace(".", "");
                  }
                  if(!e.target.value.endsWith("%")){
                    e.target.value += "%";
                  }
                }}
              />
              {errors.tasaAnual?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
            <div className="col3 campo-form">
              <span className="negritas">Plazo:</span>
              <input
                type="text"
                autoComplete="off"
                disabled={!editarDatos}
                {...register("plazoCredito", {required: true})}
                onFocus={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  if(getValues("capitalizacion")){
                    const capitalizacion = capitalizaciones.find(cap => cap.idCapitalizacion === Number(getValues("capitalizacion")));
                    setValue("periodosTotales", capitalizacion !== undefined ? capitalizacion.valorAnual * Number(e.target.value) : 0);
                  }
                }}
                onBlur={(e) => {
                  if(!e.target.value.includes("a")){
                    if(Number(e.target.value) > 1){
                      e.target.value += " años";
                    }else{
                      e.target.value += " año"
                    }
                  }
                }}
              />
              {errors.plazoCredito?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            <div className="col3 campo-form estado marginEspacio">
              <span className="negritas">Capitalización:</span>
              <select
                id="selectCapitalizacionHipoteca2"
                disabled={!editarDatos}
                {...register("capitalizacion", { required: true })}
                onChange={(e) =>{
                  if(getValues("plazoCredito")){
                    const capitalizacion = capitalizaciones.find(cap => cap.idCapitalizacion === Number(e.target.value));
                    setValue("periodosTotales", capitalizacion !== undefined ? Number(getValues("plazoCredito").replace(/[^0-9]/g, "")) * capitalizacion.valorAnual : 0 );
                  }

                  if(getValues("tasaAnual")){
                    const capitalizacion = capitalizaciones.find(cap => cap.idCapitalizacion === Number(e.target.value));
                    setTasaXPeriodoForm((Number(getValues("tasaAnual").replace(/[^0-9.]/g, "")) / 100) / capitalizacion.valorAnual);
                  }
                }}
              >
              </select>
              {errors.capitalizacion?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
            <div className="col3 campo-form estado marginEspacio">
              <span className="negritas">Banco:</span>
              <select
                id="selectBancosHipoteca2"
                disabled={!editarDatos}
                {...register("banco", { required: true })}
              >
              </select>
              {errors.banco?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
            <div className="col3 campo-form marginEspacio">
              <span className="negritas">Saldo del Crédito:</span>
              <input
                type="text"
                autoComplete="off"
                disabled={!editarDatos}
                {...register("saldoCredito", {required: true, validate: pago => Number(pago.replace(/[^0-9.]/g, "")) > 0})}
                onInput={(e) => {
                  e.target.value = textoAMoneda(e.target.value);
                }}
                onBlur={(e) => {
                  e.target.value = agergarDecimales(e.target.value);
                }}
              />
              {errors.saldoCredito?.type === "validate" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            <div className="col3 campo-form">
              <span className="negritas">Pago Mensual:</span>
              <input
                type="text"
                autoComplete="off"
                disabled={!editarDatos}
                {...register("pagoMensual", {required: true, validate: pago => Number(pago.replace(/[^0-9.]/g, "")) > round( Number(getValues("saldoCredito").replace(/[^0-9.]/g, "")) * tasaXPeriodoForm, 2) })}
                onInput={(e) => {
                  e.target.value = textoAMoneda(e.target.value);
                }}
                onBlur={(e) => {
                  e.target.value = agergarDecimales(e.target.value);
                }}
              />
              {errors.pagoMensual?.type === "required" ? (
                <span className="span">Este campo es requerido</span>
              ) : (
                errors.pagoMensual?.type === "validate" && (
                  <span className="span">Debe ser mayor al monto de Intereses</span>
                )
              )}
            </div>
            <div className="col3 campo-form">
              <span className="negritas">Periodos Totales</span>
              <input
                disabled
                type="text"
                {...register("periodosTotales")}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </div>
            <div className="col3 campo-form">
              <span className="negritas">Periodos Faltantes:</span>
              <input
                type="text"
                autoComplete="off"
                disabled
                {...register("periodosFaltantes")}
                onInput={(e) =>{
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </div>

            <div className="col3 campo-form marginEspacio">
              <span className="negritas">Valor Actual de la Propiedad Estimado*:</span>
              <input
                type="text"
                autoComplete="off"
                disabled
                {...register("estimacionValorPropiedad")}
                onInput={(e) =>{
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </div>

            {
              editarDatos && (
                <div className="dosCuttonFormularios">
                  <button type="submit" className="btn btn_morado">
                    Guardar Cambios
                  </button>
                  <button
                    className="btn btn_bco"
                    onClick={cancelarCambios}
                  >
                    Cancelar
                  </button>
                </div>
              )
            }

          </form>

          <br/>
          <hr/>

          <form onSubmit={handleSubmit(aportarEnLote)}>
            <div className="cont_grid">
              <div>
                <label>Aplicar aportación en todos los periodos</label>
                <div className="cont_inline_flex gap_small">
                  <input
                    className="max_width150px"
                    type="text"
                    autoComplete="off"
                    {...register("montoLote")}
                    onInput={(e) => {
                      e.target.value = textoAMoneda(e.target.value);
                    }}
                    onBlur={(e) => {
                      e.target.value = agergarDecimales(e.target.value);
                    }}
                  />
                  <button 
                    type="submit"
                    id="btnAmortizacionLote"
                    className="btn btn_bco"
                    >
                    Aplicar
                  </button>
                </div>
              </div>
              <div>
               <label>Recordatorio de pagos</label>
                <div className="grid3_toggle">
                  Todos los pagos
                  <span className="btn-toggle">
                    <input type="checkbox" name="checkbox" id="checkRecordatorio"
                      checked={recordatorio}
                      onChange={(e) => {
                        // Aportaciones voluntarias: True
                        // Todos los pagos: False
                        setRecordatorio(e.target.checked);
                      }}
                    />
                    <label className="toggle" htmlFor="checkRecordatorio"></label>
                  </span>
                  Solo aportaciones <br/> voluntarias
                </div>
              </div>
            </div>
          </form>

          <h3 className="encabezado_empresa">Tabla de amortización</h3>
          <div id="cont_tabla_amortizacion" className="ag-theme-material" style={{ height: 400 }}>
            <AgGridReact
              singleClickEdit={true}
              defaultColDef={{
                suppressMovable: true,
                minWidth: 100,
                wrapHeaderText: true,
                autoHeaderHeight: true,
              }}
              columnDefs={columnDefs}
              rowData={registrosAmortizacion.filter(registro => registro.saldoInicial > 0)}
              onCellValueChanged={(cell) => {
                //console.log("celda: ", cell);
                const periodo = cell.data.periodo;
                let aportaciones = {...aportacionesAmortizacion, ...{[periodo.toString()]: cell.data.aportacionAdCapital} };
                
                actualizarRegistrosAmortizacion(periodo, registrosAmortizacion, aportaciones);
                setAportacionesAmortizacion(aportaciones);
              }}
            />
          </div>
          <div className="herramientas_tabla">
            <button
              onClick={exportarAExcel}
              className="link"
            >
              <i class="fa fa-file-excel-o margin0" aria-hidden="true"></i> Exportar a Excel 
            </button>
          </div>

          <div className="dosCuttonFormularios">
            <Link 
              className="btn btn_bco campo-form" 
              to="/miHipoteca" 
              onClick={() => {
                localStorage.removeItem("idHipoteca");
              }}>
                Regresar
              </Link>
            <button className="btn btn_morado campo-form"
              onClick={guardarAportaciones}>
              Guardar
            </button>
          </div>

          <h3>
            Si realizaste algun cambio, recuerda <br />{" "}
            <span className="morado"> guardar </span> antes de salir.
          </h3>
        </div>

        <div className="nota_inferior">
          <p>
            *El valor es calculado con la ubicación del inmueble, 
            fecha y valor de compra de la propiedad. El incremento es un aproximado calculado con la tabla 
            de incrementos de valor de inmuebles publicado por la Sociedad Hipotecaria Federal.  
            El valor puede ser distinto al valor del mercado real.
          </p>
        </div>

        <div className="copyright copy_cont">
          <p>
            La presente información es únicamente para efectos de cálculo ilustrativo, 
            no representa ningún ofrecimiento formal por SATURN5 
          </p>
          <p>
            © 2024 SATURN5 | All rights reserved
          </p>
        </div>
      </div>
      
    </>
  );
}
