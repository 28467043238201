import "../../../App.css";
import "../../../sass/style.css";
import React, { useState, useEffect, useRef} from "react";
import { Cookies } from "react-cookie";
import { obtEmpresaUrl } from "../../../utils/global_functions.js";
import ObtUsuarioRemaxComponent from "../../../components/Funciones";
import { TablaClientes } from "../../../components/Tablas";
import { get, put } from "../../../utils/http.js";
import { ModalInicioSocios } from "../../../components/Modals.jsx";
import { BarChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer} from 'recharts';

function GraficaInvitaciones({idSocio}){
  const [estadisticas, setEstadisticas] = useState([
    {
      procesoNoIniciado: 0,
      documentacionAcreditados: 0,
      firmaSolicitud: 0,
      respuestaBanco: 0,
      vivienda: 0,
      avaluo: 0,
      notaria: 0,
      formalizacion: 0,
      completado: 0
    }
  ]);

  const [barSize, setBarSize] = useState(40);
  const [esqueleto, setEsqueleto] = useState(true);
  const [sinInvitaciones, setSinInvitaciones] = useState(false);

  async function recuperarEstadisticas(){
    const peticion = await get("obtEstatusInvitacionesBancos", idSocio);

    if(peticion?.statuscode === 200){
      if(peticion?.respuesta?.noInvitaciones > 0 ){
        setEstadisticas([{...peticion?.respuesta?.estadisticasInvitaciones}]);
        setEsqueleto(false);
      }else{
        setEstadisticas([{
          procesoNoIniciado: 0,
          documentacionAcreditados: 10,
          firmaSolicitud: 20,
          respuestaBanco: 30,
          vivienda: 40,
          avaluo: 50,
          notaria: 90,
          formalizacion: 70,
          completado: 60
        }]);
        setSinInvitaciones(true);
      }
    }
  }

  useEffect(() => {
    const updateBarSize = () => {
      setBarSize(window.innerWidth < 600 ? 25 : 40);
    };

    window.addEventListener('resize', updateBarSize);
    updateBarSize();

    return () => window.removeEventListener('resize', updateBarSize);
  }, []);

  useEffect(() => {
    recuperarEstadisticas();
  }, []);


  return (
    <>
      <div id="cont_grafica_invitaciones_bancos">
        <div id="contenedor-invitaciones-bancos" className="height200px">
          <ResponsiveContainer width="100%" height="100%">
            {
              esqueleto ? (
                <BarChart
                  data={estadisticas}
                  margin={{
                    top: 20,
                    bottom: 5,
                  }}
                  barSize={barSize}
                  barGap={7}
                >
                  <XAxis dataKey="titulo" hide={true}/>
                  <YAxis hide={true} type="number" domain={[0, 100]} />
                  <Bar name="Proceso no iniciado" dataKey="procesoNoIniciado" background={{ fill: '#eee' }} fill="#666666" isAnimationActive={false}/>
                  <Bar name="Documentación Acreditados" dataKey="documentacionAcreditados" background={{ fill: '#eee' }} fill="#666666" isAnimationActive={false}/>
                  <Bar name="Firma de Solicitud" dataKey="firmaSolicitud" background={{ fill: '#eee' }} fill="#666666" isAnimationActive={false}/>
                  <Bar name="Respuesta de Banco" dataKey="respuestaBanco" background={{ fill: '#eee' }} fill="#666666" isAnimationActive={false}/>
                  <Bar name="Documentación Vivienda" dataKey="vivienda" background={{ fill: '#eee' }} fill="#666666" isAnimationActive={false}/>
                  <Bar name="Avalúo" dataKey="avaluo" background={{ fill: '#eee' }} fill="#666666" isAnimationActive={false}/>
                  <Bar name="Notaría" dataKey="notaria" background={{ fill: '#eee' }} fill="#666666" isAnimationActive={false}/>
                  <Bar name="Formalización" dataKey="formalizacion" background={{ fill: '#eee' }} fill="#666666" isAnimationActive={false}/>
                  <Bar name="Completado" dataKey="completado" background={{ fill: '#eee' }} fill="#666666" isAnimationActive={false}/>
                </BarChart>
              ) : (
                <BarChart
                  data={estadisticas}
                  margin={{
                    top: 20,
                    bottom: 5,
                  }}
                  barSize={barSize}
                  barGap={7}
                >
                  <XAxis dataKey="titulo" hide={true}/>
                  <YAxis hide={true} type="number" domain={[0, 100]} />
                  <Bar name="Proceso no iniciado" dataKey="procesoNoIniciado" label={{fill: "#000", position: "top", formatter: (valor) => `${valor}%`}} background={{ fill: '#eee' }} fill="#747693" isAnimationActive={false}/>
                  <Bar name="Documentación Acreditados" dataKey="documentacionAcreditados" label={{fill: "#000", position: "top", formatter: (valor) => `${valor}%`}} background={{ fill: '#eee' }} fill="#4BBB7A" isAnimationActive={false}/>
                  <Bar name="Firma de Solicitud" dataKey="firmaSolicitud" label={{fill: "#000", position: "top", formatter: (valor) => `${valor}%`}} background={{ fill: '#eee' }} fill="#1ACAD8" isAnimationActive={false}/>
                  <Bar name="Respuesta de Banco" dataKey="respuestaBanco" label={{fill: "#000", position: "top", formatter: (valor) => `${valor}%`}} background={{ fill: '#eee' }} fill="#C5EEA9" isAnimationActive={false}/>
                  <Bar name="Documentación Vivienda" dataKey="vivienda" label={{fill: "#000", position: "top", formatter: (valor) => `${valor}%`}} background={{ fill: '#eee' }} fill="#4B75BB" isAnimationActive={false}/>
                  <Bar name="Avalúo" dataKey="avaluo" label={{fill: "#000", position: "top", formatter: (valor) => `${valor}%`}} background={{ fill: '#eee' }} fill="#9C36B2" isAnimationActive={false}/>
                  <Bar name="Notaría" dataKey="notaria" label={{fill: "#000", position: "top", formatter: (valor) => `${valor}%`}} background={{ fill: '#eee' }} fill="#BDC83E" isAnimationActive={false}/>
                  <Bar name="Formalización" dataKey="formalizacion" label={{fill: "#000", position: "top", formatter: (valor) => `${valor}%`}} background={{ fill: '#eee' }} fill="#91937D" isAnimationActive={false}/>
                  <Bar name="Completado" dataKey="completado" label={{fill: "#000", position: "top", formatter: (valor) => `${valor}%`}} background={{ fill: '#eee' }} fill="#CCA4D5" isAnimationActive={false}/>
                </BarChart>
              )
            }
          </ResponsiveContainer>
        </div>
        <div className="height200px">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              margin={{
                right: 20,
                left: 20,
              }} 
              data={estadisticas}
            >
              <Bar name="Proceso no iniciado" fill={esqueleto ? "#666666" : "#747693"}/>
              <Bar name="Documentación Acreditados" fill={esqueleto ? "#666666" : "#4BBB7A"}/>
              <Bar name="Firma de Solicitud" fill={esqueleto ? "#666666" : "#1ACAD8"}/>
              <Bar name="Respuesta de Banco" fill={esqueleto ? "#666666" : "#C5EEA9"}/>
              <Bar name="Documentación Vivienda" fill={esqueleto ? "#666666" : "#4B75BB"}/>
              <Bar name="Avalúo" fill={esqueleto ? "#666666" : "#9C36B2"}/>
              <Bar name="Notaría" fill={esqueleto ? "#666666" : "#BDC83E"}/>
              <Bar name="Formalización" fill={esqueleto ? "#666666" : "#91937D"}/>
              <Bar name="Completado" fill={esqueleto ? "#666666" : "#CCA4D5"}/>

              <Legend formatter={(value, entry, index) => <span className="fuente_secundaria leyenda">{value}</span>} layout="vertical" align="left" verticalAlign="middle" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      
      {
        sinInvitaciones && (
          <p className="margin0">
            ¡Bienvenido! Aquí verás tus métricas personalizadas en cuanto empieces a interactuar con nuestro servicio.
          </p>
        )
      }
    </>
    
  );
}

export default function PanelSocios() {
  const [isLoading, setIsLoading] = useState(true);
  const cookies = new Cookies();
  const idSocios = cookies.get("idSocio");
  const [clientes, setClientes] = useState([]);
  const [empresaLogo, setEmpresaLogo] = useState("");
  const [habilitarCarrusel, setHabilitarCarrusel] = useState(false);

  const cierraCarrusel = () => {
    setHabilitarCarrusel(false);
    localStorage.setItem("carruselInicioSesion", false);
  }

  async function obtInfoClientes(){
    const peticion = await get("getClientes", idSocios);

    if(peticion.statuscode === 200){
      setClientes(peticion.respuesta);
    }
  }

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        const empresa = await obtEmpresaUrl();
        setEmpresaLogo(empresa.configuracion.img);

        if (empresa.configuracion.nombre.toLowerCase() === "remax"){
          if (localStorage.getItem("carruselInicioSesion") === null){
            const respuesta = await get("primerInicioSesion", cookies.get("idSocio"));
            if (respuesta.statuscode === 200){
              setHabilitarCarrusel(!respuesta.primerInicioSesion);
              localStorage.setItem("carruselInicioSesion", !respuesta.primerInicioSesion);
              if (!respuesta.primerInicioSesion){
                put("primerInicioSesion", {idSocio: cookies.get("idSocio"), primerInicioSesion: true});
              }
            }
          }else{
            setHabilitarCarrusel(localStorage.getItem("carruselInicioSesion") === "true" ? true : false);
          }
        }

        await obtInfoClientes();
        
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
  }, [idSocios]);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}

      {
        habilitarCarrusel && (
          <ModalInicioSocios logo={empresaLogo} onClose={cierraCarrusel}/>
        )
      }

      <ObtUsuarioRemaxComponent idSocios={idSocios} />
      <div className="cont_Info">
        <h1 className="titt">Mis clientes</h1>
        <p className="descripcionClientes">
          Aquí puedes conocer las capacidades de crédito de tus clientes y la etapa en la que se encuentran en su crédito
        </p>

        <div className="card_contenedor">
          <h3>Invitaciones bancos</h3>
          <div>
            <GraficaInvitaciones idSocio={idSocios}/>
          </div>
        </div>

        <p className="descripcionClientes">
          Da clic en el nombre para conocer más de cada uno de tus clientes:
        </p>
        <div className="tabla_clientes">
          <TablaClientes arrData={clientes}/>
        </div>
        <br/>

      </div>
    </>
  );
}