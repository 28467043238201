export default function EstatusCredito({paso, instExterna}){

  return(
    <div>
      {
        instExterna ? (
          <div class="estatus_credito">
            {paso === 1 ? (
              <>
                <p class="active" >
                  1 Descargar Solicitud
                </p>
              </>
            ) : (
              <p title="Descargar Solicitud">
                1.
              </p>
            )}

            {paso === 2 ? (
              <p class="active" >
                2 Aceptar Solicitud
              </p>
            ) : (
              <p title="Aceptar Solicitud">
                2.
              </p>
            )}

            {paso === 3 ? (
              <p class="active" >
                3 Vivienda
              </p>
            ) : paso === 9 ? (
              <p class="active" >
                3 Vivienda
              </p>
            ) : paso === 8 ? (
              <p class="active" >
                3 Vivienda
              </p>
            ) : paso === 10 ? (
              <p class="active" >
                3 Vivienda
              </p>
            ) : (
              <p title="Vivienda">
                3.
              </p>
            )}

            {paso === 4 ? (
              <p class="active" >
                4 Avalúo
              </p>
            ) : (
              <p title="Avalúo">
                4.
              </p>
            )}

            {paso === 11 ? (
              <p class="active" >
                5 Notaría
              </p>
            ) : (
              <p title="Notaría">
                5.
              </p>
            )}

            {paso === 5 ? (
              <p class="active" >
                6 Institución Externa
              </p>
            ) : (
              <p title="Instituci&oacute;n Externa">
                6.
              </p>
            )}

            {paso === 6 ? (
              <p class="active" >
                7 Formalización y Firma
              </p>
            ) : (
              <p title="Formalizaci&oacute;n y Firma">
                7.
              </p>
            )}

            {paso === 7 ? (
              <p class="active" >
                8 Felicidades
              </p>
            ) : (
              <p title="Felicidades">
                8.
              </p>
            )}
          </div>
        ):(
          <div class="estatus_credito">
            {paso === 1 ? (
              <>
                <p class="active" >
                  1 Descargar Solicitud
                </p>
              </>
            ) : (
              <p title="Descargar Solicitud">
                1.
              </p>
            )}

            {paso === 2 ? (
              <p class="active" >
                2 Aceptar Solicitud
              </p>
            ) : (
              <p title="Aceptar Solicitud">
                2.
              </p>
            )}

            {paso === 3 ? (
              <p class="active" >
                3 Vivienda
              </p>
            ) : paso === 9 ? (
              <p class="active" >
                3 Vivienda
              </p>
            ) : paso === 8 ? (
              <p class="active" >
                3 Vivienda
              </p>
            ) : paso === 10 ? (
              <p class="active" >
                3 Vivienda
              </p>
            ) : (
              <p title="Vivienda">
                3.
              </p>
            )}

            {paso === 4 ? (
              <p class="active" >
                4 Avalúo
              </p>
            ) : (
              <p title="Avalúo">
                4.
              </p>
            )}

            {paso === 11 ? (
              <p class="active" >
                5 Notaría
              </p>
            ) : (
              <p title="Notaría">
                5.
              </p>
            )}

            {paso === 6 ? (
              <p class="active" >
                6 Formalización y Firma
              </p>
            ) : (
              <p title="Formalizaci&oacute;n y Firma">
                6.
              </p>
            )}

            {paso === 7 ? (
              <p class="active" >
                7 Felicidades
              </p>
            ) : (
              <p title="Felicidades">
                7.
              </p>
            )}
          </div>
        )
      }
      
    </div>
  )
  
}